import React, { useState, useMemo, useEffect } from "react";
import jwt_decode from "jwt-decode";
// import LandingModal from "../components/LandingModal";
import ResponseModal from "../components/ResponseModal";
import Loader from "../components/Loader";
import {
  completeKYCFlow,
  getKycDetails,
  getWebSDKVersions,
} from "../utils/apiRepository";
import * as Sentry from "@sentry/react";
import "./container.scss";

const LinkKyc = () => {
  const [loading, setLoading] = useState(true);
  const [identifier, setIdentifier] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [redirectURL, setredirectURL] = useState("");
  const [workflowId, setWorkflowId] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const [appId, setAppId] = useState("");
  const [inputs, setInputs] = useState({});
  const [defaultLanguage, setDefaultLanguage] = useState({});
  const [responseReceived, setResponseReceived] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [redirectTime, setRedirectTime] = useState(5);
  const [showGetStarted, setShowGetStarted] = useState(true);
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const fetchUrlDetails = async () => {
    try {
      const urlIdentifier = queryParams.get("identifier");
      setIdentifier(urlIdentifier);
      const details = await getKycDetails(urlIdentifier);
      setredirectURL(details.redirectUrl);
      setJwtToken(details.jwtToken);
      const decodedToken = jwt_decode(details.jwtToken);
      setAppId(decodedToken.appId);
      setWorkflowId(details.workflowId);
      setInputs(details.inputs);
      setDefaultLanguage(details.defaultLanguage || "en");
      setRedirectTime(details.redirectTime);
      const identifier = urlIdentifier.split("_");
      identifier.shift();
      const transactionId = identifier.join("_");
      setTransactionId(transactionId);
      setShowGetStarted(details.showGetStartedScreen);
      return { appId: decodedToken.appId };
    } catch (err) {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureException(err, {
          tags: {
            source: "customVersionLinkKycFetchDetails",
          },
          extra: {
            source: "customVersionLinkKycFetchDetails",
            identifier: queryParams.get("identifier"),
          },
        });
      }
      setResponseReceived(true);
      if (
        err.response &&
        err.response.status === 400 &&
        err.response.data &&
        err.response.data.result.error.message === "Link expired"
      ) {
        setResponseStatus(`invalid_${err.response.data.result.error.status}`);
      } else {
        setResponseStatus("error");
      }
      setLoading(false);
    }
  };

  const fetchWebSDKVersions = async () => {
    try {
      const versions = await getWebSDKVersions();
      return versions;
    } catch (err) {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureException(err, {
          tags: {
            source: "customVersionLinkKycFetchSDKVersion",
          },
          extra: {
            source: "customVersionLinkKycFetchSDKVersion",
            identifier: queryParams.get("identifier"),
          },
        });
      }
      setResponseReceived(true);
      setResponseStatus("error");
      setLoading(false);
    }
  };

  const calculateVersionToUse = (sdkVersionConfig, appId) => {
    const weight = Math.random();
    if (sdkVersionConfig[appId]) {
      const appIdWeight = Number(sdkVersionConfig[appId]["weight"]);
      return weight < appIdWeight
        ? sdkVersionConfig[appId]["version"]
        : sdkVersionConfig["default"]["version"];
    } else if (sdkVersionConfig["globalCanary"]) {
      const globalCanaryWeight = Number(
        sdkVersionConfig["globalCanary"]["weight"]
      );
      return weight < globalCanaryWeight
        ? sdkVersionConfig["globalCanary"]["version"]
        : sdkVersionConfig["default"]["version"];
    } else {
      return sdkVersionConfig["default"]["version"];
    }
  };

  const getScriptUrl = (version) =>
    `https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/hyperverge-web-sdk@${version}/src/sdk.min.js`;

  const performInitOperations = async () => {
    const [urlDetails, sdkVersionConfig] = await Promise.all([
      fetchUrlDetails(),
      fetchWebSDKVersions(),
    ]);

    const versionToUse = calculateVersionToUse(
      sdkVersionConfig,
      urlDetails.appId
    );
    console.log("Using version ", versionToUse);
    const sdkUrl = getScriptUrl(versionToUse);
    const script = document.createElement("script");
    script.setAttribute("id", "websdk-script");
    script.src = sdkUrl;
    script.onload = () => {
      setLoading(false);
    };

    document.body.appendChild(script);
  };

  const callback = async (HyperKycResult) => {
    try {
      delete HyperKycResult.details;
      console.log("HYPERKYC RESULT", HyperKycResult);
      setResponseReceived(true);
      window.HyperSnapSDK.endUserSession();
      if (
        HyperKycResult.errorCode &&
        HyperKycResult.errorCode === 401 &&
        HyperKycResult.errorMessage === "Token Expired"
      )
        setResponseStatus("expired");
      else setResponseStatus(HyperKycResult.status);

      if (
        HyperKycResult.status !== "error" &&
        HyperKycResult.status !== "user_cancelled"
      ) {
        await completeKYCFlow(identifier, HyperKycResult.status);
      }
    } catch (err) {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureMessage(
          `Error captured for ${transactionId}, SDK response: ${JSON.stringify(
            HyperKycResult
          )}`,
          { level: "info" }
        );
        Sentry.captureException(err);
      }
      throw err;
    }
  };

  const startKYC = async () => {
    try {
      localStorage.removeItem("eventType");
      const config = new window.HyperKycConfig(
        jwtToken,
        workflowId,
        transactionId,
        showGetStarted
      );
      config.setInputs(inputs);
      config.setDefaultLangCode(defaultLanguage);

      window.HyperKYCModule.launch(config, callback);
    } catch (err) {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureException(err);
      }
      setResponseReceived(true);
      setResponseStatus("error");
    }
  };

  useEffect(() => {
    performInitOperations();
  }, []);

  const getContainerContent = () => {
    if (loading) {
      return <Loader />;
    }
    if (responseReceived) {
      if (redirectURL && redirectTime === 0) {
        let url;
        if (appId === "ezs12s" || appId === "qw9rjg") {
          url = `${redirectURL}?transactionId=${transactionId}&status=${responseStatus}`;
        } else {
          url = new URL(redirectURL);
          url.searchParams.append("transactionId", transactionId);
          url.searchParams.append("status", responseStatus);
        }
        window.location = url;
        return <Loader />;
      }
      return (
        <ResponseModal
          redirectURL={redirectURL}
          redirectTime={redirectTime}
          status={responseStatus}
          transactionId={transactionId}
          appId={appId}
        />
      );
    }
    {
      startKYC();
    }
  };

  return (
    <div id="kyc_page">
      <div id="kyc_container">{getContainerContent()}</div>
    </div>
  );
};

export default LinkKyc;
