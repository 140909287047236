import React from "react";
import security from "../../assests/icons/security-small.svg";
import "./ResponseModal.scss";

const Footer = () => {
  return (
    <div id="footer__item_container">
      <img src={security} />
      <span>powered by HyperVerge</span>
    </div>
  );
};

export default Footer;
