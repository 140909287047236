import React from "react";
import "./components.scss";

const Loader = () => {
  return (
    <div id="loader">
      <div className="loader-box loader"></div>
    </div>
  );
};

export default Loader;
