import React from "react";
import PropTypes from "prop-types";
import {
  statusMsg,
  translateApplicationStatus,
} from "../utils/applicationStatus";
import "./components.scss";
import Animations from "./ResponseModal/Animations";
import Redirect from "./ResponseModal/Redirect";
import Footer from "./ResponseModal/Footer";

const ResponseModal = ({
  status,
  redirectURL,
  redirectTime,
  transactionId,
  appId,
}) => {
  return (
    <div id="response_modal__container">
      <div id="response_modal__sub_container">
        <div>
          <p id="response_modal__heading">
            {statusMsg[translateApplicationStatus[status]].heading}
          </p>
          <p id="response_modal__subText">
            {statusMsg[translateApplicationStatus[status]].subText}
          </p>
        </div>
        <Animations status={status} />
        {redirectURL ? (
          <Redirect
            url={redirectURL}
            transactionId={transactionId}
            status={status === "expired" ? "error" : status}
            appId={appId}
            redirectTime={redirectTime}
          />
        ) : (
          <p id="response_modal__redirect">
            {statusMsg[translateApplicationStatus[status]].bottomText}
          </p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ResponseModal;

ResponseModal.propTypes = {
  status: PropTypes.string.isRequired,
  redirectURL: PropTypes.string.isRequired,
  redirectTime: PropTypes.number.isRequired,
  transactionId: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
};
