import React from "react";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import Success from "../../assests/animations/success.json";
import Failure from "../../assests/animations/failure.json";
import Processing from "../../assests/animations/processing.json";
import ErrorState from "../../assests/animations/somethingWrong.json";
import "./ResponseModal.scss";

const Animations = ({ status }) => {
  const getAnimation = () => {
    if (status.includes("approved")) return Success;
    else if (status.includes("needs_review")) return Processing;
    else if (status === "error" || status === "user_cancelled")
      return ErrorState;
    else return Failure;
  };

  return <Lottie id="animation" animationData={getAnimation()} loop={false} />;
};

export default Animations;

Animations.propTypes = {
  status: PropTypes.string.isRequired,
};
